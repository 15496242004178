import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index"

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

import axios from "axios"
axios.defaults.baseURL = "http://124.171.37.173:3000"

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App)
    .use(vuetify)
    .use(router)

.mount('#app')