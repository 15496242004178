<template>
    <v-container class="container ma-0">
        <v-row v-if="!loggedIn && !createAccount" class="d-flex justify-center" style="margin-top: 100px">
            <v-expand-transition class="mt-10">
                <v-card width="40%" height="500px">
                    <v-container class="pt-14">
                        <v-row class="d-flex justify-center">
                            <h1>FlyDreamAir</h1>
                        </v-row>
                        <v-row class="pl-15 mt-12 pb-0">
                            <v-col cols="6" class="d-flex justify-center">
                                <span class="text-grey" style="font-size: 25px">Login</span>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center pb-0">
                            <v-col cols="6">
                                <v-text-field v-model="loginEmail" hide-details placeholder="Email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center pb-0">
                            <v-col cols="6">
                                <v-text-field v-model="loginPwd" hide-details placeholder="Password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-15 mt-0">
                            <v-col cols="7" class="d-flex justify-center">
                                <span class="text-blue" style="font-size: 14px; cursor: pointer" @click="createAccount = true, loggedIn = false">Create an account</span>
                            </v-col>
                            <v-col cols="5">
                                <v-btn :disabled="!loginEmail || !loginPwd" @click="login()" variant="text" style="text-transform: capitalize">Log In</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-expand-transition>
        </v-row>

        <v-row v-if="createAccount && !loggedIn" class="d-flex justify-center" style="margin-top: 100px">
            <v-expand-transition class="mt-10">
                <v-card width="40%" height="500px">
                    <v-container class="pt-14">
                        <v-row class="d-flex justify-center">
                            <h1>FlyDreamAir</h1>
                        </v-row>
                        <v-row class="pl-15 mt-12 pb-0">
                            <v-col cols="8" class="d-flex justify-center">
                                <span class="text-grey" style="font-size: 25px">Create Account</span>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center pb-0">
                            <v-col cols="6">
                                <v-text-field v-model="signupEmail" hide-details placeholder="Email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center pb-0">
                            <v-col cols="6">
                                <v-text-field v-model="signupPwd" hide-details placeholder="Password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-15 mt-0">
                            <v-col cols="8" style="margin-left: 120px">
                                <v-btn :disabled="!signupEmail || !signupPwd" @click="signup()" variant="text" style="text-transform: capitalize">Sign Up</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-expand-transition>
        </v-row>

        <v-row v-if="loggedIn && !createAccount" class="d-flex justify-center" style="margin-top: 100px">
            <v-expand-transition class="mt-10">
                <v-card v-show="hostCard" width="60%" height="'300px'">
                    <v-tabs v-model="tabModel" color="orange" height="60px">
                        <v-tab width="260px" value="book" prepend-icon="mdi-airplane-takeoff" class="tab">Book a flight</v-tab>
                        <v-tab width="260px" value="manage" prepend-icon="mdi-receipt-text-edit-outline" class="tab">Manage booking</v-tab>
                    </v-tabs>
                    <v-window v-model="tabModel">
                        <v-window-item value="book">
                            <v-container class="pa-8">
                                <v-row>
                                    <v-col cols="4">
                                        <v-container>
                                            <v-row>
                                                <span class="text-grey">Fly From</span>
                                            </v-row>
                                            <v-row class="pt-3"> 
                                                <v-text-field hide-details placeholder="Start typing..." label="Airport / City" v-model="search_flyFrom" @focus="searchResults = false, searchDelay = false"></v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-expand-transition>
                                                    <v-card v-show="computed_flyFrom.length > 0 && !searchResults" class="mt-5 pl-3 pr-3" elevation="0" width="100%" height="200px">
                                                        <v-row class="pt-1" style="max-height: 400px; overflow-y: scroll"> 
                                                            <v-card elevation="0" text v-for="(airport, i) in this.computed_flyFrom" :key="i" @click="select_flyFrom = airport, search_flyFrom = airport.name" class="list-button" style="border-radius: 0px" width="100%">
                                                                <v-container class="d-flex align-start flex-column pa-5 pt-7">
                                                                    <v-row>{{airport.name}} ({{airport.iata}})</v-row>
                                                                    <v-row class="text-grey">{{airport.icao}}</v-row>
                                                                </v-container>
                                                            </v-card>
                                                        </v-row>
                                                    </v-card>
                                                </v-expand-transition>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                    <v-col cols="4" class="d-flex flex-column" style="width: 400px; margin-top: 25px">
                                        <v-row>
                                            <v-img width="300px" height="60px" :src="require('../assets/book_decal3.png')"></v-img>
                                        </v-row>

                                        <v-row class="d-flex justify-center mt-10">
                                            <v-btn v-if="select_flyTo && select_flyFrom" @click="searchFlights()" color="orange" prepend-icon="mdi-magnify">Search for Flights</v-btn>
                                        </v-row>
                                    </v-col> 
                                    <v-col cols="4">
                                        <v-container>
                                            <v-row>
                                                <span class="text-grey">Fly To</span>
                                            </v-row>
                                            <v-row class="pt-3"> 
                                                <v-text-field hide-details placeholder="Start typing..." label="Airport / City" v-model="search_flyTo" @focus="searchResults = false, searchDelay = false"></v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-expand-transition>
                                                    <v-card v-show="computed_flyTo.length > 0 && !searchResults" class="mt-5 pl-3 pr-3" elevation="0" width="100%" height="200px">
                                                        <v-row class="pt-1" style="max-height: 400px; overflow-y: scroll"> 
                                                            <v-card elevation="0" text v-for="(airport, i) in this.computed_flyTo" :key="i" @click="select_flyTo = airport, search_flyTo = airport.name" class="list-button" style="border-radius: 0px" width="100%">
                                                                <v-container class="d-flex align-start flex-column pa-5 pt-7">
                                                                    <v-row>{{airport.name}} ({{airport.iata}})</v-row>
                                                                    <v-row class="text-grey">{{airport.icao}}</v-row>
                                                                </v-container>
                                                            </v-card>
                                                        </v-row>
                                                    </v-card>
                                                </v-expand-transition>
                                            </v-row>
                                        </v-container>
                                    </v-col>    
                                </v-row>
                            </v-container>
                        </v-window-item>
                        <v-window-item value="manage">
                            <v-container class="pa-8 pt-15">
                                <v-row>
                                    <span class="text-grey ml-5">Search for your Booking</span>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-container>
                                            <v-row> 
                                                <v-text-field hide-details placeholder="Start typing..." label="Booking Number" v-model="search_bookingNum" @focus="searchResults = false, searchDelay = false"></v-text-field>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-container>
                                            <v-row> 
                                                <v-text-field hide-details placeholder="Start typing..." label="Surname" v-model="search_surname" @focus="searchResults = false, searchDelay = false"></v-text-field>
                                            </v-row>
                                        </v-container>
                                    </v-col>    
                                </v-row>
                                <v-row>
                                    <v-btn class="ml-4 mt-6" elevation="0" variant="outlined" color="orange" prepend-icon="mdi-magnify" :disabled="!search_bookingNum || !search_surname" @click="searchForBooking()">Search for Booking</v-btn>
                                </v-row>
                            </v-container>
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-expand-transition>

            <!-- BOOK - Results -->
                <v-expand-transition class="mt-10">
                    <v-card width="60%" class="pb-6" v-show="searchResults && searchDelay">
                        <v-container v-if="searchResults">
                            <v-row class="mt-1 pb-2">
                                <v-card width="100%" class="ml-10 mr-10 mt-5" color="#F4F4F4" elevation="0" @click="selectFlight(searchResults)">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-container class="d-flex justify-center flex-column mt-3">
                                                    <v-row class="text-h4">{{searchResults.flightNo}}</v-row>
                                                    <v-row class="text-grey">{{searchResults.planeType}} ({{searchResults.aircraftRego}})</v-row>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="7">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <v-row>
                                                                <span class="text-h4">{{select_flyFrom.iata}}</span>
                                                                <span class="d-flex align-end ml-2 pb-1 text-grey">{{select_flyFrom.icao}}</span>
                                                            </v-row>
                                                            <v-row class="text-grey" style="font-size: 12px">{{select_flyFrom.location}}, {{select_flyFrom.country}}</v-row>
                                                            <v-row>
                                                                <span class="">{{searchResults.depTime}}</span>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-row class="d-flex justify-center mr-7" >
                                                                <span style="position: absolute; margin-left: 40px; bottom: 50px;">{{searchResults.duration}}</span>
                                                                <v-img :src="require('../assets/right_arrow.png')"></v-img>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-row>
                                                                <span class="text-h4">{{select_flyTo.iata}}</span>
                                                                <span class="d-flex align-end ml-2 pb-1 text-grey">{{select_flyTo.icao}}</span>
                                                            </v-row>
                                                            <v-row class="text-grey" style="font-size: 12px">{{select_flyTo.location}}, {{select_flyTo.country}}</v-row>
                                                            <v-row>
                                                                <span class="">{{searchResults.arrTime}}</span>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="2" class="mt-5 d-flex align-end flex-column">
                                                <span class="text-h5">from ${{searchResults.cost}}</span>
                                                <span class="text-grey" style="font-size: 14px; cursor: pointer"><u>Book Now ></u></span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-row>
                        </v-container>
                        <v-container v-else>
                            No flights found :(
                        </v-container>
                    </v-card>
                </v-expand-transition>
            <!--  -->


            <!-- MANAGE - Results -->
                <v-expand-transition class="mt-10">
                    <v-card width="60%" class="pb-6" v-show="bookingSearchResults && bookingSearchDelay">
                        <v-container v-if="bookingSearchResults">
                            <v-row class="mt-1 pb-2">
                                <v-card width="100%" class="ml-10 mr-10 mt-5" :key="i" color="#F4F4F4" elevation="0" 
                                @click="manageFlight(bookingSearchResults)">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-container class="d-flex justify-center flex-column mt-3">
                                                    <v-row class="text-h4">{{bookingSearchResults.flightNo}}</v-row>
                                                    <v-row class="text-grey">{{bookingSearchResults.planeType}} ({{bookingSearchResults.aircraftRego}})</v-row>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="7">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <v-row>
                                                                <span class="text-h4">{{bookingSearchResults.depAirportIata}}</span>
                                                                <span class="d-flex align-end ml-2 pb-1 text-grey">{{bookingSearchResults.depAirportIcao}}</span>
                                                            </v-row>
                                                            <v-row class="text-grey" style="font-size: 12px">{{bookingSearchResults.depAirportLoc}}, {{bookingSearchResults.depAirportCountry}}</v-row>
                                                            <v-row>
                                                                <span class="">{{bookingSearchResults.depTime}}</span>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-row class="d-flex justify-center mr-7" >
                                                                <span style="position: absolute; margin-left: 40px; bottom: 50px;">{{bookingSearchResults.duration}}</span>
                                                                <v-img :src="require('../assets/right_arrow.png')"></v-img>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-row>
                                                                <span class="text-h4">{{bookingSearchResults.arrAirportIata}}</span>
                                                                <span class="d-flex align-end ml-2 pb-1 text-grey">{{bookingSearchResults.arrAirportIcao}}</span>
                                                            </v-row>
                                                            <v-row class="text-grey" style="font-size: 12px">{{bookingSearchResults.arrAirportLoc}}, {{bookingSearchResults.arrAirportCountry}}</v-row>
                                                            <v-row>
                                                                <span class="">{{bookingSearchResults.arrTime}}</span>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-row>
                        </v-container>
                        <v-container v-else>
                            No flights found :(
                        </v-container>
                    </v-card>
                </v-expand-transition>
            <!--  -->
        </v-row>

        <!-- BOOK - Flight Details -->
        <v-row style="top: 200px">
            <v-col cols="3">
                <v-expand-transition>
                    <v-card v-show="focusFlight" class="pa-5 ml-15" width="400px">
                        <v-row>
                            <v-col cols="8">
                                <span class="text-grey">You're flying to</span>
                            </v-col>
                            <v-col cols="4" class="pt-1">
                                <v-btn elevation="0" class="text-blue" @click="searchFlights()" style="text-transform: capitalize; font-size: 12px">Back to Flights</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 ml-1">
                            <span class="text-h4">{{select_flyTo.iata}}</span>
                            <span class="d-flex align-end ml-2 pb-1 text-grey">{{select_flyTo.icao}}</span>
                        </v-row>
                        <v-row class="text-grey ml-1" style="font-size: 12px">{{select_flyTo.location}}, {{select_flyTo.country}}</v-row>
                        <v-row class="mt-5 ml-1 pb-3">{{focusFlight.planeType}} ({{focusFlight.aircraftRego}})</v-row>
                    </v-card>
                </v-expand-transition>

                <v-expand-transition>
                    <v-card v-show="focusFlight" class="pa-7 ml-15 mt-10" width="400px">
                        <v-row class="ml-1">
                            <span class="text-grey">Current Cost</span>
                        </v-row>
                        <v-row class="ml-1">
                            <span class="d-flex align-end pb-1 text-h5">{{runningCost}}</span>
                        </v-row>
                        <v-row v-for="(cost, i) in runningCostDetails" :key="i">
                            <v-col cols="8" class="text-grey">{{cost.label}}</v-col>
                            <v-col cols="4" class="text-grey d-flex justify-end">${{cost.cost}}</v-col>
                        </v-row>
                    </v-card>
                </v-expand-transition>
            </v-col>
            <v-col class="pl-15 pr-15">
                <v-expand-transition>
                    <v-card v-show="focusFlight" class="pa-5 pt-9" width="100%">
                        <v-container class="d-flex justify-start">
                            <v-row class="pa-0">
                                <v-col cols="7" class="d-flex justify-start" style="overflow-y: scroll; max-height: 600px;">
                                    <v-timeline appear side="end" :line-color="hasBookingPhaseBeenCompleted">
                                        <v-timeline-item size="large" :dot-color="(firstName && lastName) && 'green' || 'red' ">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">1</span>
                                            </template>

                                            <v-card elevation="0" class="pt-5">
                                                <v-container style="z-index: 99">
                                                    <v-row><b>Personal Details</b></v-row>
                                                </v-container>
                                            </v-card>
                                            
                                            <v-row class="mt-1 ml-1">
                                                <v-text-field v-model="firstName" label="First Name" style="width: 200px" outlined></v-text-field>
                                                <v-text-field class="pl-2" v-model="lastName" label="Last Name" style="width: 200px" outlined></v-text-field>
                                            </v-row>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(selectedDate) && 'green' || 'red' " size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">2</span>
                                            </template>

                                            <v-card elevation="0" class="pt-5">
                                                <v-container style="z-index: 99">
                                                    <v-row><b>Choose your departure date</b></v-row>
                                                </v-container>
                                            </v-card>
                                            <Datepicker class="pb-10 mt-3" circle lang="en" placeholder="Choose Date" v-model="selectedDate"></Datepicker>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(focusFlight.ticketType && selectedSeat) && 'green' || 'red'" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">3</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-container class="pa-0">
                                                    <v-row class="ml-2 mt-2"><b>Choose your Ticket</b></v-row>
                                                    <v-card elevation="2" width="400px" style="cursor: pointer" @click="focusFlight.ticketType = typeClass" :color="typeClass == focusFlight.ticketType && 'light-green' || '#F3F3F3'" v-for="typeClass in (this.focusFlight.planeType && this.planeDetails[focusFlight.planeType].classes) || {}" :key="typeClass" class="ml-0 mb-4 pl-3 pr-3 pt-2 pb-2 mt-5">
                                                        <v-row>
                                                            <v-col cols="8" class="d-flex align-center">{{this.classes[typeClass].displayName}}</v-col>
                                                            <v-col cols="4" class="d-flex align-end flex-column">
                                                                <span class="text-h5">${{Math.floor(this.classes[typeClass].multiplier * focusFlight.cost)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(baggageType && 'green' || 'white')" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">4</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-container>
                                                    <v-row>
                                                        <b>Additional Baggage</b>
                                                    </v-row>
                                                    <v-row style="font-size: 11px">
                                                        <i>7kg Carry on Baggage Included</i>
                                                    </v-row>
                                                    <v-row class="mt-5">
                                                        <v-btn-toggle @update:modelValue="newBaggageType" v-model="baggageType" color="green">
                                                            <v-tooltip text="Upgrade to 14kg Carry-On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn elevation="0">
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="14kg Checked Baggage" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="14kg Checked Baggage and 14kg Carry-On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="25kg Checked Baggage" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="25kg Checked Baggage and 14kg Carry On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                        </v-btn-toggle>
                                                    </v-row>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(snack1 || snack2 || snack3 || ecoMeal || premMeal || busMeal || firstMeal) && 'green' || 'white'" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">5</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-row class="ml-1 mt-3"><b>Feeling Extra?</b></v-row>
                                                <v-container class="ml-1">
                                                    Our Menu Options
                                                    <v-container class="mb-5">
                                                        <v-row class="pt-0"><v-checkbox v-model="snack1" hide-details label="Pringles ($5)"></v-checkbox></v-row>
                                                        <v-row class="pt-0"><v-checkbox v-model="snack2" hide-details label="Coke Can ($4)"></v-checkbox></v-row>
                                                        <v-row class="pt-0"><v-checkbox v-model="snack3" hide-details label="Smiths Original ($4.50)"></v-checkbox></v-row>
                                                    </v-container>

                                                    <v-row v-if="this.focusFlight.cost > 1500 && this.focusFlight.ticketType == 'eco' " class="ml-1">Included Main Meal</v-row>
                                                    <v-container v-if="this.focusFlight.cost > 1500 && this.focusFlight.ticketType == 'eco' " class="mt-0">
                                                        <v-radio-group v-model="chosenMeal">
                                                            <v-radio value="1" hide-details label="Chicken Schnitzel Burger"></v-radio>
                                                            <v-radio value="2" hide-details label="Satay Chicken Sticks"></v-radio>
                                                            <v-radio value="3" hide-details label="Butter Chicken with Rice"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-row v-if="this.focusFlight.ticketType != 'eco' && this.focusFlight.ticketType" class="ml-1">Choose a Main Meal</v-row>

                                                    <v-container v-if="this.focusFlight.ticketType == 'prem'">
                                                        <v-radio-group v-model="chosenMeal">
                                                            <v-radio value="1" hide-details label="Grilled Chicken with Bean & Corn Salsa, Mash and Broccoli"></v-radio>
                                                            <v-radio value="2" hide-details label="Thai Pepper Beef with Jasine Rice and Pak Choi"></v-radio>
                                                            <v-radio value="3" hide-details label="FlyDream Pizza"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-container v-if="this.focusFlight.ticketType == 'bus'">
                                                        <v-radio-group v-model="chosenMeal">
                                                            <v-radio value="1" hide-details label="Pan-fried Beef Tenderloin, with jus lie and steamed vegetables"></v-radio>
                                                            <v-radio value="2" hide-details label="Tapioca-Soy Dumplings, with rice and pak choi"></v-radio>
                                                            <v-radio value="3" hide-details label="Antipasti Wagyu and Roasted Chicken, served with roasted vegetables"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-container v-if="this.focusFlight.ticketType == 'first'">
                                                        <v-radio-group v-model="chosenMeal">
                                                            <v-radio value="1" hide-details label="Caviar, with cihves, gratred egg, sour cream, served with blinis"></v-radio>
                                                            <v-radio value="2" hide-details label="Pan-Seared Salmon Trout, with Mash, and sauteed vegetables"></v-radio>
                                                            <v-radio value="3" hide-details label="Pan-Fried Wagyu Beef Tenderloin, with vegetables"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-row class="mt-5 pb-5">
                                            <v-btn style="position: relative; left: 85px" :color="hasBookingPhaseBeenCompleted" @click="bookConfirm = true" :loading="bookingFlight">Book Tickets</v-btn>
                                        </v-row>
                                    </v-timeline>
                                </v-col>
                                <v-col cols="5" style="overflow-y: scroll; max-height: 600px;">
                                    <v-fade-transition>
                                        <v-card :style="this.selectedSeat && 'border: 1px solid green' || 'border: 1px solid red'" v-show="this.focusFlight.ticketType" elevation="2" style="position: sticky; top: 0px; height: 900px; overflow-y: scroll">
                                            <Seat_737Vue  @chooseSeat="selectSeat" v-if="this.focusFlight.planeType == 'B737-800'" :chosenClass="this.focusFlight.ticketType"></Seat_737Vue>
                                            <Seat_A330Vue @chooseSeat="selectSeat" v-if="this.focusFlight.planeType == 'A330-300'" :chosenClass="this.focusFlight.ticketType"></Seat_A330Vue>
                                            <Seat_787Vue  @chooseSeat="selectSeat" v-if="this.focusFlight.planeType == 'B787-9'"   :chosenClass="this.focusFlight.ticketType"></Seat_787Vue>
                                            <Seat_A380Vue @chooseSeat="selectSeat" v-if="this.focusFlight.planeType == 'A380-861'" :chosenClass="this.focusFlight.ticketType"></Seat_A380Vue>
                                        </v-card>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expand-transition>
            </v-col>
        </v-row>


        <!-- MANAGE - Flight Details -->
        <v-row style="top: 100px">
            <v-col cols="3">
                <v-expand-transition>
                    <v-card v-show="managingFlight" class="pa-5 ml-15" width="400px">
                        <v-row>
                            <v-col cols="8">
                                <span class="text-grey text-h5">Manage your Flight</span>
                            </v-col>
                            <v-col cols="4" class="pt-2">
                                <v-btn elevation="0" class="text-blue" @click="returnToFlights()" style="text-transform: capitalize; font-size: 12px">Back to Flights</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-expand-transition>

                <v-expand-transition>
                    <v-card v-show="managingFlight" class="pa-7 ml-15 mt-10" width="400px">
                        <v-row class="ml-1">
                            <span class="text-grey">Current Cost</span>
                        </v-row>
                        <v-row class="ml-1">
                            <span class="d-flex align-end pb-1 text-h5">{{m_runningCost}}</span>
                        </v-row>
                        <v-row v-for="(cost, i) in m_runningCostDetails" :key="i">
                            <v-col cols="8" class="text-grey">{{cost.label}}</v-col>
                            <v-col cols="4" class="text-grey d-flex justify-end">${{cost.cost}}</v-col>
                        </v-row>
                    </v-card>
                </v-expand-transition>
            </v-col>
            <v-col class="pl-15 pr-15">
                <v-expand-transition>
                    <v-card v-show="managingFlight" class="pa-5 pt-9" width="100%">
                        <v-container class="d-flex justify-start">
                            <v-row class="pa-0">
                                <v-col cols="7" class="d-flex justify-start" style="overflow-y: scroll; max-height: 600px;">
                                    <v-timeline appear side="end" :line-color="hasBookingPhaseBeenCompleted">
                                        <v-timeline-item size="large" :dot-color="(managingFlight.fname && managingFlight.lname) && 'green' || 'red' ">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">1</span>
                                            </template>

                                            <v-card elevation="0" class="pt-5">
                                                <v-container style="z-index: 99">
                                                    <v-row><b>Personal Details</b></v-row>
                                                </v-container>
                                            </v-card>

                                            <v-row class="mt-1 ml-1">
                                                <v-text-field disabled v-model="managingFlight.fname" label="First Name" style="width: 200px" outlined></v-text-field>
                                                <v-text-field disabled class="pl-2" v-model="managingFlight.lname" label="Last Name" style="width: 200px" outlined></v-text-field>
                                            </v-row>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(managingFlight.depDate) && 'green' || 'red' " size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">2</span>
                                            </template>

                                            <v-card elevation="0" class="pt-5">
                                                <v-container style="z-index: 99">
                                                    <v-row><b>Choose your departure date</b></v-row>
                                                </v-container>
                                            </v-card>
                                            <Datepicker class="pb-10 mt-3" disabled circle lang="en" placeholder="Choose Date" v-model="managingFlight.depDate"></Datepicker>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(managingFlight.ticketType && managingFlight.chosenSeat) && 'green' || 'red'" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">3</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-container class="pa-0">
                                                    <v-row class="ml-2 mt-2"><b>Choose your Ticket</b></v-row>
                                                    <v-card elevation="2" width="400px" style="cursor: pointer" @click="managingFlight.ticketType = typeClass" :color="typeClass == managingFlight.ticketType && 'light-green' || '#F3F3F3'" 
                                                    v-for="typeClass in (this.managingFlight.planeType && this.planeDetails[managingFlight.planeType].classes) || {}" :key="typeClass" class="ml-0 mb-4 pl-3 pr-3 pt-2 pb-2 mt-5">
                                                        <v-row>
                                                            <v-col cols="8" class="d-flex align-center">{{this.classes[typeClass].displayName}}</v-col>
                                                            <v-col cols="4" class="d-flex align-end flex-column">
                                                                <span class="text-h5">${{Math.floor(this.classes[typeClass].multiplier * managingFlight.cost)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(managingFlight.baggage && 'green' || 'white')" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">4</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-container>
                                                    <v-row>
                                                        <b>Additional Baggage</b>
                                                    </v-row>
                                                    <v-row style="font-size: 11px">
                                                        <i>7kg Carry on Baggage Included</i>
                                                    </v-row>
                                                    <v-row class="mt-5">
                                                        <v-btn-toggle v-model="managingFlight.baggage" color="green">
                                                            <v-tooltip text="Upgrade to 14kg Carry-On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn elevation="0">
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="14kg Checked Baggage" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="14kg Checked Baggage and 14kg Carry-On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="25kg Checked Baggage" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                            <v-tooltip text="25kg Checked Baggage and 14kg Carry On" location="bottom">
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-suitcase</v-icon>
                                                                        <v-icon v-bind="props" size="x-large">mdi-bag-carry-on</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                        </v-btn-toggle>
                                                    </v-row>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item :dot-color="(managingFlight.snacks|| managingFlight.meal) && 'green' || 'white'" size="large">
                                            <template v-slot:icon>
                                                <span style="font-size: 24px">5</span>
                                            </template>

                                            <v-card elevation="0">
                                                <v-row class="ml-1 mt-3"><b>Feeling Extra?</b></v-row>
                                                <v-container class="ml-1">
                                                    Our Menu Options
                                                    <v-container class="mb-5" v-if="managingFlight.snacks">
                                                        <v-row class="pt-0"><v-checkbox v-model="managingFlight.snacks[0]" hide-details label="Pringles ($5)"></v-checkbox></v-row>
                                                        <v-row class="pt-0"><v-checkbox v-model="managingFlight.snacks[1]" hide-details label="Coke Can ($4)"></v-checkbox></v-row>
                                                        <v-row class="pt-0"><v-checkbox v-model="managingFlight.snacks[2]" hide-details label="Smiths Original ($4.50)"></v-checkbox></v-row>
                                                    </v-container>

                                                    <v-row v-if="managingFlight.cost > 1500 && managingFlight.ticketType == 'eco' " class="ml-1">Included Main Meal</v-row>
                                                    <v-container v-if="managingFlight.cost > 1500 && managingFlight.ticketType == 'eco' " class="mt-0">
                                                        <v-radio-group v-model="managingFlight.meal">
                                                            <v-radio value="1" hide-details label="Chicken Schnitzel Burger"></v-radio>
                                                            <v-radio value="2" hide-details label="Satay Chicken Sticks"></v-radio>
                                                            <v-radio value="3" hide-details label="Butter Chicken with Rice"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-row v-if="managingFlight.ticketType != 'eco' && managingFlight.ticketType" class="ml-1">Choose a Main Meal</v-row>

                                                    <v-container v-if="managingFlight.ticketType == 'prem'">
                                                        <v-radio-group v-model="managingFlight.meal">
                                                            <v-radio value="1" hide-details label="Grilled Chicken with Bean & Corn Salsa, Mash and Broccoli"></v-radio>
                                                            <v-radio value="2" hide-details label="Thai Pepper Beef with Jasine Rice and Pak Choi"></v-radio>
                                                            <v-radio value="3" hide-details label="FlyDream Pizza"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-container v-if="managingFlight.ticketType == 'bus'">
                                                        <v-radio-group v-model="managingFlight.meal">
                                                            <v-radio value="1" hide-details label="Pan-fried Beef Tenderloin, with jus lie and steamed vegetables"></v-radio>
                                                            <v-radio value="2" hide-details label="Tapioca-Soy Dumplings, with rice and pak choi"></v-radio>
                                                            <v-radio value="3" hide-details label="Antipasti Wagyu and Roasted Chicken, served with roasted vegetables"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>

                                                    <v-container v-if="managingFlight.ticketType == 'first'">
                                                        <v-radio-group v-model="managingFlight.meal">
                                                            <v-radio value="1" hide-details label="Caviar, with cihves, gratred egg, sour cream, served with blinis"></v-radio>
                                                            <v-radio value="2" hide-details label="Pan-Seared Salmon Trout, with Mash, and sauteed vegetables"></v-radio>
                                                            <v-radio value="3" hide-details label="Pan-Fried Wagyu Beef Tenderloin, with vegetables"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>
                                                </v-container>
                                            </v-card>
                                        </v-timeline-item>
                                        <v-row class="mt-5 pb-5">
                                            <v-btn style="position: relative; left: 85px" :color="hasBookingPhaseBeenCompleted" @click="saveFlightChanges()" :loading="bookingFlight">Save Changes</v-btn>
                                        </v-row>
                                    </v-timeline>
                                </v-col>
                                <v-col cols="5" style="overflow-y: scroll; max-height: 600px;">
                                    <v-fade-transition>
                                        <v-card :style="managingFlight.chosenSeat && 'border: 1px solid green' || 'border: 1px solid red'" v-show="managingFlight.ticketType" elevation="2" style="position: sticky; top: 0px; height: 900px; overflow-y: scroll">
                                            <Seat_737Vue  @chooseSeat="updateSelectedSeat" v-if="managingFlight.planeType == 'B737-800'" :chosenClass="managingFlight.ticketType" :selectedSeat="managingFlight.chosenSeat"></Seat_737Vue>
                                            <Seat_A330Vue @chooseSeat="updateSelectedSeat" v-if="managingFlight.planeType == 'A330-300'" :chosenClass="managingFlight.ticketType" :selectedSeat="managingFlight.chosenSeat"></Seat_A330Vue>
                                            <Seat_787Vue  @chooseSeat="updateSelectedSeat" v-if="managingFlight.planeType == 'B787-9'"   :chosenClass="managingFlight.ticketType" :selectedSeat="managingFlight.chosenSeat"></Seat_787Vue>
                                            <Seat_A380Vue @chooseSeat="updateSelectedSeat" v-if="managingFlight.planeType == 'A380-861'" :chosenClass="managingFlight.ticketType" :selectedSeat="managingFlight.chosenSeat"></Seat_A380Vue>
                                        </v-card>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expand-transition>
            </v-col>
        </v-row>

        <v-dialog v-model="bookConfirm" persistent dark>
            <v-row class="d-flex justify-center" v-if="!bookingFlight && !bookingConfirmed">
                <v-card width="1000px" class="pa-6 pb-6">
                    <v-card-title class="text-h4" style="font-family: 'Ubuntu', sans-serif !important">Booking Confirmation</v-card-title>

                    <v-row class="pt-3 pl-8 pb-10">
                        Please ensure that all of your personal information is correct. Once you have booked your flight, your Personal Details and Departure Date cannot be changed
                    </v-row>

                    <v-card-actions class="mt-10 ml-4 d-flex align-end">
                        <v-btn style="text-transform: capitalize" color="green" prepend-icon="mdi-check-circle-outline" class="pl-4 pr-4" @click="bookFlight()">Confirm Booking</v-btn>
                        <v-btn style="text-transform: capitalize" color="red" prepend-icon="mdi-close-circle-outline" class="pl-4 pr-4" @click="bookConfirm = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>


            <v-row class="d-flex justify-center" v-if="bookingFlight">
                <v-card width="1000px" height="630px" class="pa-6">
                    <v-container class="d-flex justify-center align-center flex-column" style="margin-top: 150px">
                        <v-row>
                            <v-progress-circular
                                indeterminate
                                color="blue"
                                size="120"
                            ></v-progress-circular>
                        </v-row>
                        <v-row class="mt-15">
                            <span class="text-h4" style="font-family: 'Ubuntu', sans-serif !important">Hang Tight</span>
                        </v-row>
                        <v-row>
                            <span class="mt-3" style="font-size: 18px; font-family: 'Ubuntu', sans-serif !important">We are processing your booking now.</span>
                        </v-row>
                    </v-container>
                </v-card>
            </v-row>


            <v-row class="d-flex justify-center" v-if="bookingConfirmed">
                <v-card width="1000px" height="650px" class="pa-6">
                    <v-row class="d-flex justify-center">
                        <svg id="checkmark" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle addClass" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check addClass" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </v-row>
                    <v-row class="d-flex justify-center">
                        <span class="text-h4" style="font-family: 'Ubuntu', sans-serif !important">Booking Confirmed</span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                        <span class="mt-3" style="font-size: 18px">Awesome! You're going to {{select_flyTo.location}}, {{select_flyTo.country}}</span>
                    </v-row>
                    <v-row class="d-flex justify-center mt-15">
                        <span style="font-size: 18px">Booking ID: {{bookingConfirmationCode}}</span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                        <span class="grey--text mt-3" style="font-size: 14px">Please keep record of this as you may be asked to reference this Booking ID at a later date</span>
                    </v-row>
                    <v-row class="d-flex justify-center mt-8">
                        <v-btn elevation="0" variation="text" @click="bookingConfirmed = false, bookConfirm = false, loggedIn = true, focusFlight = false, managingFlight = false, hostCard = true">Return to Home</v-btn>
                    </v-row>
                </v-card>
            </v-row>
        </v-dialog>

        <v-row>
            <v-snackbar :color="displaySnack.colour" v-model="displaySnack"> {{ displaySnack.text }} </v-snackbar>
        </v-row>
  </v-container>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';

import Seat_737Vue from '../components/Seat_737.vue';
import Seat_A330Vue from "../components/Seat_A330.vue"
import Seat_787Vue from "../components/Seat_787.vue"
import Seat_A380Vue from "../components/Seat_A380.vue"

import airportsJSON from "../components/airports.json"
import flightsJSON from "../components/flights.json"
import planesJSON from "../components/planes.json"

export default {
    components: {
      Datepicker: VueDatepickerUi,
      Seat_737Vue,
      Seat_A330Vue,
      Seat_787Vue,
      Seat_A380Vue
    },

    data: () => ({
        tabModel: "book",
        airports: airportsJSON,
        flights: flightsJSON,
        planes: planesJSON,
        loggedIn: false,

        // VAR - Login
            loginEmail: "",
            loginPwd: "",
            createAccount: false,
            signupEmail: "",
            signupPwd: "",

        // VAR - Booking
            search_flyFrom: "",
            search_flyTo: "",
            select_flyFrom: false,
            select_flyTo: false,
            searchResults: false,
            searchDelay: false,
            flightCost: 0,
            planeType: "",
            flightNumber: "",
            planeRego: "",
            hostCard: true,
            focusFlight: false,
            bookConfirm: false,
            selectedDate: null,
            firstName: "",
            lastName: "",
            baggageType: null,
            chosenBaggage: [],
            snack1: null,
            snack2: null,
            snack3: null,
            chosenMeal: null,
            bookingFlight: false,
            bookingConfirmed: false,
            selectedSeat: null,
            bookingConfirmationCode: null,
        // 



        // VAR - Manage
            search_bookingNum: null,
            search_surname: null,
            bookingSearchResults: [],
            bookingSearchDelay: false,
            managingFlight: false,

        // 



        // VAR - General
            planeDetails: {
                'B737-800': { classes: ["eco", "bus"]},
                'A330-300': { classes: ["eco", "bus"]},
                'B787-9': { classes: ["eco", "prem", "bus"]},
                'A380-861': { classes: ["eco", "prem", "bus", "first"]},
            },

            classes: {
                'eco': { multiplier: 1.0, displayName: "Economy" },
                'prem': { multiplier: 1.12, displayName: "Premium Economy" },
                'bus': { multiplier: 1.3, displayName: "Business Class" },
                'first': { multiplier: 1.55, displayName: "First Class" },
            },

            bagTypes: {
                0: {label: 'Additional Carry-On', cost: "42"},
                1: {label: '14kg Checked Baggage', cost: "47"},
                2: {label: '14kg Checked Baggage and Additional Carry-On', cost: "62"},
                3: {label: '25kg Checked Baggage', cost: "71"},
                4: {label: '25kg Checked Baggage and Additional Carry-On', cost: "83"}
            },

            displaySnack: false,
        // 
    }),

    methods: {
        async searchFlights() {
            setTimeout(() => {
                this.hostCard = true
                this.searchDelay = false
            }, 500)
            this.focusFlight = false

            let payload = { fromId: this.select_flyFrom.id, toId: this.select_flyTo.id }

            for (var flight of this.flights) {
                if (flight.depAirport == payload.fromId && flight.arrAirport == payload.toId) {
                    this.searchResults = flight

                    for (var plane of this.planes) {
                        if (plane.rego == flight.aircraftRego) {
                            this.searchResults.planeRego = plane.rego
                            this.searchResults.planeType = plane.type
                        }
                    }

                    setTimeout(() => {this.searchDelay = true}, 500)
                    return
                }
            }
        },

        returnToFlights() {
            setTimeout(() => {
                this.hostCard = true
                this.searchDelay = false
            }, 500)
            this.managingFlight = false
        },

        selectFlight(flight) {
            this.hostCard = false
            this.searchDelay = false

            setTimeout(() => {this.focusFlight = flight}, 500)
        },

        manageFlight(flight) {
            this.hostCard = false
            this.bookingSearchDelay = false

            setTimeout(() => {
                flight.baggage = Number(flight.baggage)
                flight.snacks = JSON.parse(flight.snacks)
                this.managingFlight = flight
            }, 500)
        },

        newBaggageType() {
            let type = this.bagTypes[this.baggageType] || null
            this.chosenBaggage = type
        },

        async bookFlight() {
            this.bookingFlight = true

            this.focusFlight.fname = this.firstName
            this.focusFlight.lname = this.lastName

            let additionalDetails = {
                snacks: JSON.stringify([this.snack1, this.snack2, this.snack3]),
                meal: this.chosenMeal,
                baggage: this.baggageType
            }

            let bookingReference = "FDA"
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let counter = 0;
            while (counter < 8) {
                bookingReference += characters.charAt(Math.floor(Math.random() * characters.length));
                counter += 1;
            }
            bookingReference = bookingReference.slice(0, -1)

            let bookingObj = { 
                id: bookingReference,
                fname: this.focusFlight.fname,
                lname: this.focusFlight.lname,
                flightNo: this.focusFlight.flightNo,
                ticketType: this.focusFlight.ticketType,
                chosenSeat: this.focusFlight.chosenSeat,
                baggage: additionalDetails.baggage,
                snacks: additionalDetails.snacks,
                meal: additionalDetails.meal,
                depDate: this.selectedDate
            }

            let storedBookings = localStorage.getItem("214_bookings")
            if (!storedBookings) { storedBookings = "[]" }
            storedBookings = JSON.parse(storedBookings)
            storedBookings.push(bookingObj)
            localStorage.setItem("214_bookings", JSON.stringify(storedBookings))
            
            setTimeout(() => {
                this.bookingFlight = false
                this.bookingConfirmed = true
                this.bookingConfirmationCode = bookingReference

                var cols = document.getElementsByClassName('checkmark');
                for(var i = 0; i < cols.length; i++) {
                    cols[i].style.display = 'block';
                }
            }, 2000)
        },

        login() {
            let customers = localStorage.getItem("214_customers")
            if (!customers) { 
                customers = "[]" 
                this.displaySnack = {text: "No account found. Please check login details", colour: "red"}
                setTimeout(() => {
                    this.displaySnack = false
                }, 6000)
                return
            } else {
                customers = JSON.parse(customers)
                for (var customer of customers) {
                    if (customer.email == this.loginEmail) {
                        if (customer.pwd == this.loginPwd) {
                            this.createAccount = false
                            this.loggedIn = true
                            return
                        }
                    }
                }

                this.displaySnack = {text: "No account found. Please check login details", colour: "red"}
                setTimeout(() => {
                    this.displaySnack = false
                }, 6000)
            }
        },

        signup() {
            let newCustomer = { 
                email: this.signupEmail,
                pwd: this.signupPwd,
            }

            let customers = localStorage.getItem("214_customers")
            if (!customers) { customers = "[]" }
            customers = JSON.parse(customers)
            customers.push(newCustomer)
            localStorage.setItem("214_customers", JSON.stringify(customers))

            this.displaySnack = {text: "Successfully created account", colour: "green"}
            this.createAccount = false
            setTimeout(() => {
                this.displaySnack = false
            }, 6000)
        },

        selectSeat(e) {
            this.selectedSeat = e
            this.focusFlight.chosenSeat = e
        },

        updateSelectedSeat(e) {
            this.selectedSeat = e
            this.managingFlight.chosenSeat = e
        },

        async searchForBooking() {
            let storedBookings = JSON.parse(localStorage.getItem("214_bookings"))

            if (!storedBookings) {
                this.bookingSearchResults = []
                return
            } else {
                for (var booking of storedBookings) {
                    if (booking.id == this.search_bookingNum && booking.lname == this.search_surname) {
                        for (var flight of this.flights) {
                            if (booking.flightNo == flight.flightNo) {
                                this.bookingSearchResults = flight

                                for (var plane of this.planes) {
                                    if (plane.rego == flight.aircraftRego) {
                                        this.bookingSearchResults.planeRego = plane.rego
                                        this.bookingSearchResults.planeType = plane.type
                                    }
                                }

                                for (var airport of this.airports) {

                                    if (flight.depAirport == airport.id) {
                                        this.bookingSearchResults.depAirportIata = airport.iata
                                        this.bookingSearchResults.depAirportIcao = airport.icao
                                        this.bookingSearchResults.depAirportLoc = airport.location
                                        this.bookingSearchResults.depAirportCountry = airport.country
                                    }

                                    if (flight.arrAirport == airport.id) {
                                        this.bookingSearchResults.arrAirportIata = airport.iata
                                        this.bookingSearchResults.arrAirportIcao = airport.icao
                                        this.bookingSearchResults.arrAirportLoc = airport.location
                                        this.bookingSearchResults.arrAirportCountry = airport.country
                                    }
                                }

                                this.bookingSearchResults.baggage = booking.baggage
                                this.bookingSearchResults.snacks = booking.snacks
                                this.bookingSearchResults.chosenSeat = booking.chosenSeat
                                this.bookingSearchResults.meal = booking.meal

                                this.bookingSearchResults.fname = booking.fname
                                this.bookingSearchResults.lname = booking.lname
                                this.bookingSearchResults.ticketType = booking.ticketType
                                this.bookingSearchResults.depDate = booking.depDate

                                this.bookingSearchResults.id = booking.id
                            }
                        }
                        setTimeout(() => {this.bookingSearchDelay = true}, 500)
                    }
                }
            }
            
        },

        async saveFlightChanges() {
            let bookingObj = { 
                id: this.managingFlight.id,
                fname: this.managingFlight.fname,
                lname: this.managingFlight.lname,
                flightNo: this.managingFlight.flightNo,
                ticketType: this.managingFlight.ticketType,
                chosenSeat: this.managingFlight.chosenSeat,
                baggage: this.managingFlight.baggage,
                snacks: JSON.stringify(this.managingFlight.snacks),
                meal: this.managingFlight.meal,
                depDate: this.managingFlight.depDate
            }

            let storedBookings = localStorage.getItem("214_bookings")
            storedBookings = JSON.parse(storedBookings)


            for (var bookingIndex in storedBookings) {
                var booking = storedBookings[bookingIndex]

                if (booking.id == this.managingFlight.id) {
                    storedBookings.splice(bookingIndex, 1)
                }
            }

            storedBookings.push(bookingObj)
            localStorage.setItem("214_bookings", JSON.stringify(storedBookings))

            this.displaySnack = {text: "Successfully saved flight changes", colour: "green"}
            setTimeout(() => {
                this.displaySnack = false
            }, 6000)
            
            this.returnToFlights()
        },
    },

    computed: {
        computed_flyFrom() {
            if (!this.search_flyFrom) { return [] }
            var results = []
            var search = this.search_flyFrom.toUpperCase()
            if (search == "" || search.length < 3) { return false }

            for (var airport of this.airports) {
                if (airport.icao.toUpperCase().indexOf(search) > -1) { results.push(airport) }
                else if (airport.iata.toUpperCase().indexOf(search) > -1) { results.push(airport)  }
                else if (airport.name.toUpperCase().indexOf(search) > -1) { results.push(airport)  }
            }

            return results
        },

        computed_flyTo() {
            if (!this.search_flyTo) { return [] }
            var results = []
            var search = this.search_flyTo.toUpperCase()
            if (search == "" || search.length < 3) { return false }

            for (var airport of this.airports) {
                if (airport.icao.toUpperCase().indexOf(search) > -1) { results.push(airport) }
                else if (airport.iata.toUpperCase().indexOf(search) > -1) { results.push(airport)  }
                else if (airport.name.toUpperCase().indexOf(search) > -1) { results.push(airport)  }
            }

            return results
        },

        runningCost() {
            let ticketType = this.focusFlight.ticketType
            if (ticketType == null) { return 'Please choose a ticket'}

            let ticketCost = Math.floor((this.classes[ticketType].multiplier * this.focusFlight.cost)*100)/100
            let baggageCost = (this.chosenBaggage && this.chosenBaggage.cost) || 0

            let sum = (Number(ticketCost) + Number(baggageCost))

            if (this.snack1) { sum += 5 }
            if (this.snack2) { sum += 4 }
            if (this.snack3) { sum += 4.5 }

            return "$" + sum
        },

        m_runningCost() {
            let ticketType = this.managingFlight.ticketType
            if (ticketType == null) { return 'Please choose a ticket'}

            let ticketCost = Math.floor((this.classes[ticketType].multiplier * this.managingFlight.cost)*100)/100
            let baggageCost = this.bagTypes[this.managingFlight.baggage].cost

            let sum = (Number(ticketCost) + Number(baggageCost))

            if (this.managingFlight.snacks[0]) { sum += 5 }
            if (this.managingFlight.snacks[1]) { sum += 4 }
            if (this.managingFlight.snacks[2]) { sum += 4.5 }

            return "$" + sum
        },

        runningCostDetails() {
            let details = []

            let ticketType = this.focusFlight.ticketType
            if (ticketType == null) { return null}

            let ticketDetails = this.classes[ticketType]
            let ticketCost = Math.floor((this.classes[ticketType].multiplier * this.focusFlight.cost)*100)/100

            details.push({label: ticketDetails.displayName + " ticket", cost: ticketCost, type: "ticket"})
            
            this.newBaggageType()

            if (this.baggageType != null) {
                details.push({label: this.chosenBaggage.label, cost: this.chosenBaggage.cost, type: "bag"})
            }

            if (this.snack1) { details.push({label: "Snack - Pringles", cost: 5, type: "snack"}) }
            if (this.snack2) { details.push({label: "Snack - Coke Can", cost: 4, type: "snack"}) }
            if (this.snack3) { details.push({label: "Snack - Smiths Original", cost: 4.5, type: "snack"}) }

            return details
        },

        m_runningCostDetails() {
            let details = []

            let ticketType = this.managingFlight.ticketType
            if (ticketType == null) { return null}

            let ticketDetails = this.classes[ticketType]
            let ticketCost = Math.floor((this.classes[ticketType].multiplier * this.managingFlight.cost)*100)/100

            details.push({label: ticketDetails.displayName + " ticket", cost: ticketCost, type: "ticket"})
            
            this.newBaggageType()

            if (this.managingFlight.baggage != null) {
                details.push({label: this.bagTypes[this.managingFlight.baggage].label, cost: this.bagTypes[this.managingFlight.baggage].cost, type: "bag"})
            }

            if (this.managingFlight.snacks[0]) { details.push({label: "Snack - Pringles", cost: 5, type: "snack"}) }
            if (this.managingFlight.snacks[1]) { details.push({label: "Snack - Coke Can", cost: 4, type: "snack"}) }
            if (this.managingFlight.snacks[2]) { details.push({label: "Snack - Smiths Original", cost: 4.5, type: "snack"}) }

            return details
        },

        hasBookingPhaseBeenCompleted() {
            if (this.firstName && this.lastName && this.selectedDate && this.focusFlight.ticketType && this.selectedSeat) {
                return 'green'
            }

            return 'red'
        }
    }
    
}
</script>

<style lang="scss" scoped>

    .container {
        height: 100%;
        min-width: 100%;
        background-image: url("../assets/HP_Backer.jpg");
        background-position: center;
        background-size: cover;
    }

    .tab {
        text-transform: capitalize;
        font-weight: 800;
        font-size: 16px;
    }

    .list-button {
        text-transform: capitalize;
        text-align: start;
    }

    .list-button:hover {
        background-color:#e6e6e6
    }

    .checkmark__circle {
        stroke-dasharray: 266;
        stroke-dashoffset: 266;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #7ac142;
        fill: none;
        animation: stroke .9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark {
        width: 156px;
        height: 156px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .4s ease-in-out .5s forwards, scale .3s ease-in-out .9s both;
    }

    .checkmark__check.animateElement {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%, 100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 80px #7ac142;
        }
    }
</style>
