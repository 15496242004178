<template>
    <v-container class="plane">
        <div class="exit fuselage"></div>
        
        <v-btn-toggle @update:modelValue="updateSeat()" v-model="chosenSeat" style="height: 100%; padding-bottom: 20px" class="fuselage d-flex flex-column pt-5" >
            <v-row v-show="chosenClass == 'bus'" class="b-seats" v-for="r in 3" :key="r" style="max-height: 20px">
                <v-btn :value="'b_' + r + s" rounded="lg" class="b-seat" v-for="s in ['A', 'B', 'C', 'D']" :key="s" width="50px" height="40px" selected-class="chosenSeat">
                    {{r + s}} 
                </v-btn>
            </v-row>

            <v-row class="seats" v-for="r in 8" :key="r" style="max-height: 20px;">
                <v-btn :value="'e_' + (r+3) + s" rounded="lg" class="seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F']" text :key="s" height="40px" size="small" selected-class="chosenSeat">{{(r + 3) + s}}</v-btn>
            </v-row>
        

            <div class="exit mt-7"></div>

            <v-row class="seats" v-for="r in 18" :key="r" style="max-height: 20px">
                <v-btn :value="'e_' + (r+11) + s" rounded="lg" class="seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F']" text :key="s" height="40px" size="small" selected-class="chosenSeat">{{(r + 11) + s}} </v-btn>
            </v-row>
        </v-btn-toggle>
    </v-container>
</template>

<script>
export default {
    props: {
        chosenClass: String,
        selectedSeat: String,
    },

    data() {
        return {
            chosenSeat: null,
        }
    },

    created() {
        setTimeout(() => {this.chosenSeat = this.selectedSeat}, 50)
    },

    methods: {
        updateSeat() {
            setTimeout(() => { this.$emit('chooseSeat', this.chosenSeat) }, 50)
        }
    }
}
</script>

<style lang="scss" scoped>
    .plane {
        margin: 20px auto;
        max-width: 450px;
        margin-bottom: 30px;
    }

    .exit {
        position: relative;
        height: 50px;
        &:before,
        &:after {
            content: "EXIT";
            font-size: 14px;
            line-height: 18px;
            padding: 0px 2px;
            display: block;
            position: absolute;
            background: green;
            color: white;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }

    .fuselage {
        border-right: 5px solid #d8d8d8;
        border-left: 5px solid #d8d8d8;
    }

    .b-seats {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    .b-seat {
        margin-right: 5px;
        background-color: #dddddd;

        &:nth-child(2) {
            margin-right: 27%;
        }
    }
    .seats {
        padding-top: 20px;
        margin-bottom: 20px;
        margin-left: 5px;
    }

    .seat {
        margin-right: 5px;
        background-color: #dddddd;

        &:nth-child(3) {
            margin-right: 19%;
        }
    }

    .chosenSeat {
        background-color: #8BC34A;
    }
</style>