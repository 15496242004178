<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

* {
  font-family: 'Ubuntu', sans-serif !important;
}

:root {
  --scale: 1;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
