<template>
    <v-container class="plane">
        <v-btn-toggle @update:modelValue="updateSeat()" v-model="chosenSeat" style="height: 100%; padding-bottom: 20px" class="fuselage d-flex flex-column pt-5" >
            <div v-if="chosenClass != 'eco'" class="mb-10 d-flex justify-center text-orange">UPPER DECK</div>
                <div v-if="chosenClass != 'eco' && chosenClass != 'pre'" class="exit"></div>

                <div v-if="chosenClass == 'bus' || chosenClass == 'first'" class="d-flex justify-center mb-5">Business Class</div>
                <v-row v-show="chosenClass == 'bus' || chosenClass == 'first'" class="b-seats" v-for="r in 5" :key="r" style="max-height: 20px">
                    <v-btn :value="'b_' + r + s" rounded="lg" class="b-seat" v-for="s in ['A', 'B', 'C', 'D']" :key="s" width="50px" height="40px" selected-class="chosenSeat">
                        {{r + s}} 
                    </v-btn>
                </v-row>

                <div v-if="chosenClass != 'eco' && chosenClass != 'pre'"  class="exit mt-7 mb-7"></div>

                <v-row v-show="chosenClass == 'bus' || chosenClass == 'first'" class="b-seats" v-for="r in 13" :key="r" style="max-height: 20px">
                    <v-btn :value="'b_' + (r + 5) + s" rounded="lg" class="b-seat" v-for="s in ['A', 'B', 'C', 'D']" :key="s" width="50px" height="40px" selected-class="chosenSeat">
                        {{(r + 5) + s}} 
                    </v-btn>
                </v-row>

                <div v-if="chosenClass != 'eco'"  class="mt-10"></div>

                <div v-if="chosenClass != 'eco'" class="d-flex justify-center mb-5">Premium Economy</div>
                <v-row v-show="chosenClass != 'eco'" class="pre-seats" v-for="r in 10" :key="r" style="max-height: 20px">
                    <v-btn :value="'p_' + (r + 18) + s" rounded="lg" class="pre-seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F', 'G']" :key="s" width="50px" height="40px" size="small" selected-class="chosenSeat">
                        {{(r + 18) + s}} 
                    </v-btn>
                </v-row>

            <div class="mt-10 d-flex justify-center text-orange">MAIN DECK</div>

                <div v-if="chosenClass == 'first'" class="d-flex justify-center mb-5 mt-6">First Class</div>
                <v-row v-show="chosenClass == 'first'" class="f-seats" v-for="r in 6" :key="r" style="max-height: 20px;">
                    <v-btn :value="'f_' + r + s" rounded="lg" class="f-seat" v-for="s in ['A', 'B', 'C']" text :key="s" height="40px" width="80px" selected-class="chosenSeat">
                        {{r + s}}
                    </v-btn>
                </v-row>

                <div class="exit mt-7"></div>

                <div class="d-flex justify-center mt-5">Economy</div>
                <v-row class="seats" v-for="r in 18" :key="r" style="max-height: 20px;">
                    <v-btn :value="'e_' + (r+15) + s" rounded="lg" class="seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']" text :key="s" height="40px" size="x-small" selected-class="chosenSeat">
                        {{(r + 15) + s}}
                    </v-btn>
                </v-row>
        

                <div class="exit mt-7"></div>

                <v-row class="seats" v-for="r in 11" :key="r" style="max-height: 20px">
                    <v-btn :value="'e_' + (r+20) + s" rounded="lg" class="seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']" text :key="s" height="40px" size="x-small" selected-class="chosenSeat">
                        {{(r + 20) + s}} 
                    </v-btn>
                </v-row>

                <div class="exit mt-7"></div>

                <v-row class="seats" v-for="r in 10" :key="r" style="max-height: 20px">
                    <v-btn :value="'e_' + (r+20) + s" rounded="lg" class="seat" v-for="s in ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']" text :key="s" height="40px" size="x-small" selected-class="chosenSeat">
                        {{(r + 20) + s}} 
                    </v-btn>
                </v-row>

                <div class="exit mt-7"></div>
        </v-btn-toggle>
    </v-container>
</template>

<script>
export default {
    props: {
        chosenClass: String,
        selectedSeat: String,
    },

    data() {
        return {
            chosenSeat: null,
        }
    },

    created() {
        setTimeout(() => {this.chosenSeat = this.selectedSeat}, 50)
    },

    methods: {
        updateSeat() {
            setTimeout(() => { this.$emit('chooseSeat', this.chosenSeat) }, 50)
        }
    }
}
</script>

<style lang="scss" scoped>
    .plane {
        margin: 20px auto;
        max-width: 450px;
        margin-bottom: 30px;
    }

    .exit {
        position: relative;
        height: 50px;
        &:before,
        &:after {
            content: "EXIT";
            font-size: 14px;
            line-height: 18px;
            padding: 0px 2px;
            display: block;
            position: absolute;
            background: green;
            color: white;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }

    .fuselage {
        border-right: 5px solid #d8d8d8;
        border-left: 5px solid #d8d8d8;
    }

    .f-seats {
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .f-seat {
        margin-right: 5px;
        background-color: #dddddd;

        &:nth-child(1) {
            margin-right: 15%;
        }

        &:nth-child(2) {
            margin-right: 15%;
        }
    }

    .b-seats {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    .b-seat {
        margin-right: 5px;
        background-color: #dddddd;

        &:nth-child(1) {
            margin-right: 15%;
        }

        &:nth-child(3) {
            margin-right: 15%;
        }
    }

    .pre-seats {
        margin-bottom: 20px;
        margin-left: 2px;
    }

    .pre-seat {
        margin-right: 3px;
        background-color: #dddddd;

        &:nth-child(2) {
            margin-right: 5%;
        }

        &:nth-child(5) {
            margin-right: 5%;
        }
    }

    .seats {
        padding-top: 20px;
        margin-bottom: 20px;
        margin-left: 5px;
    }

    .seat {
        margin-right: 5px;
        background-color: #dddddd;

        &:nth-child(2) {
            margin-right: 9%;
        }

        &:nth-child(6) {
            margin-right: 9%;
        }
    }

    .chosenSeat {
        background-color: #8BC34A;
    }
</style>